import React from "react";
import { Tooltip, styled } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  "& .MuiTooltip-tooltip": {
    padding: 10,
    backgroundColor: "#f1f1f1",
    color: "#000",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.43,
    maxWidth: 300,
  },
}));

const Item = ({ data, searchValue, offset }) => {
  const colorScale = (value) => {
    if (value > 500000) {
      return { color: "green" };
    } else if (value >= 100000 && value <= 499999) {
      return { color: "blue" };
    } else if (value <= 99999 && value >= 10000) {
      return { color: "orange" };
    } else if (value <= 99999 && value >= 1000) {
      return { color: "red" };
    }
  };
  return (
    <>
      {data &&
        data
          .filter((coin) =>
            coin.name.toLowerCase().startsWith(searchValue.toLowerCase())
          )
          .slice(offset, offset + 20)
          .map((item, index) => (
            <tbody key={index}>
              <tr className="table-row">
                <td>{item.name}</td>
                <td>{item.sigle}</td>
                <td>{item.position}</td>
                <td>{item.state}</td>
                <td>{item.job}</td>
                <LightTooltip
                  disableFocusListener
                  disableHoverListener={!item.assetsDesc || item.assetsDesc.trim() === ""}
                  enterTouchDelay={0}
                  interactive={true}
                  placement="top"
                  title={
                    <div className="scroll-tooltip">
                      <React.Fragment>
                        {item.assetsDesc}
                      </React.Fragment>
                    </div>
                  }
                >
                  <td className="asset-cell">
                    {!item.assetsDesc || item.assetsDesc.trim() === "" ? "-" : item.assetsDesc}
                  </td>  
                </LightTooltip>
                <td style={colorScale(parseFloat(item.totalValue))}>
                  {item.totalValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
            </tbody>
          ))}
    </>
  );
};

export default Item;
import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import CryptoPoliticos from "../components/crypto-politicos/crypto2022";
import CryptoMarquee from "../components/crypto-marquee/marquee2022";

const Version2022 = () => {
  return (
    <>
      <div className="app page-padding">
        <CryptoMarquee />
        <Header />
        <CryptoPoliticos />
      </div>
      <Footer />
    </>
  );
};

export default Version2022;

import "./App.css";
import { Routes, Route } from "react-router-dom";
import Version2024 from "./pages/version2024";
import Version2022 from "./pages/version2022";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Version2024 />} />
        <Route exact path="/2022" element={<Version2022 />} />
      </Routes>
    </>
  );
}

export default App;

import React from "react";
import Header from "../components/header";
import CryptoPoliticos from "../components/crypto-politicos/crypto2024";
import CryptoMarquee from "../components/crypto-marquee/marquee2024";
import Footer from "../components/footer";

const Version2024 = () => {
  return (
    <>
      <div className="app page-padding">
        <CryptoMarquee />
        <Header />
        <CryptoPoliticos />
      </div>
      <Footer/>
    </>
  );
};

export default Version2024;
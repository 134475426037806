import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import axios from "axios";

import Menu from "./menu";
import Item from "./item";

import IconSearch from "../../../assets/search-outline.svg";
import LoadingGif from "../../../assets/spin-gray.gif";

const API_URL = "https://api.paradigma.education/api/v2";

const CryptoPoliticos = () => {
  const [data, setData] = useState([]);
  const [top3Parties, setTop3Parties] = useState([]);
  const [top3Candidates, setTop3Candidates] = useState([]);
  const [searchResults, setSearchResults] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/candidates?page=${currentPage}`)
      .then((res) => {
        setData(res.data.candidates);
        setTop3Candidates(res.data.top3Candidates);
        setTop3Parties(res.data.top3Parties);
        setPageCount(res.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
  };

  const handleSearch = () => {
    setLoading(true);
    if (searchValue && searchValue.trim() !== "") {
      axios
        .get(`${API_URL}/candidates?searchName=${searchValue}`)
        .then((res) => {
          setSearchResults(res.data.candidates);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSearchResults(null);
    }
  };

  const handleKeyDown = (e) => {
    if (searchValue && e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.trim() === "") {
      setSearchResults(null);
    }
  };

  const displayData = searchResults !== null ? searchResults : data;

  return (
    <>
      <section className="crypto-politicos-container">
        <div className="search-container">
          <div className="search-input">
            <input
              type="text"
              placeholder="Busca"
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="search-icon" onClick={handleSearch}>
            <img alt="icon search" src={IconSearch} />
          </div>
        </div>

        <div className="ranking-container">
          <div className="ranking-card">
            <h3 className="ranking-title">🏴 Partido mais coiner</h3>
            <ul className="ranking-list">
              {top3Parties
                .sort((a, b) => (a.name === "PL" ? -1 : 1))
                .map((party, index) => (
                  <li className="ranking-item" key={index}>
                    <span>
                      {`${party.name || "-"} (${party.total || 0} CANDIDATOS)`}
                    </span>
                    <span className="bg-green">
                      R${" "}
                      {party?.totalValue
                        ? party.totalValue.toLocaleString("pt-br")
                        : "0"}
                    </span>
                  </li>
                ))}
            </ul>
          </div>

          <div className="ranking-card">
            <h3 className="ranking-title">🐳 Top baleias</h3>
            <ul className="ranking-list">
              {top3Candidates.map((candidate, index) => (
                <li className="ranking-item" key={index}>
                  <span>{candidate.name || "-"}</span>
                  <span className="bg-green">
                    R${" "}
                    {candidate?.totalValue
                      ? candidate.totalValue.toLocaleString("pt-br")
                      : "-"}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {loading ? (
          <div className="loader-main-container">
            <img className="loader-main" src={LoadingGif} alt="loading..." />
          </div>
        ) : (
          <table className="crypto-politicos-table">
            <Menu data={displayData} setData={setData} />
            <Item data={displayData} searchValue={searchValue} />
          </table>
        )}
        {!displayData && (
          <div className="no-data-message">Nenhum dado encontrado.</div>
        )}
        <ReactPaginate
          breakLabel="..."
          previousLabel="<"
          nextLabel=">"
          pageRangeDisplayed={3}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
          containerClassName={
            searchValue
              ? "pagination-wrapper-display-none"
              : "pagination-wrapper"
          }
          pageClassName="pagination-component"
          pageLinkClassName="pagination-text"
          nextLinkClassName="pagination-text"
          previousLinkClassName="pagination-text"
          previousClassName="pagination-component"
          nextClassName="pagination-component"
          breakClassName="pagination-component"
          breakLinkClassName="pagination-text"
        />
      </section>
    </>
  );
};

export default CryptoPoliticos;

import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import axios from "axios";

import Menu from "./menu";
import Item from "./item";

import IconSearch from "../../../assets/search-outline.svg";
import LoadingGif from "../../../assets/spin-gray.gif";

const CryptoPoliticos = () => {
  const [coins, setCoins] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [top3Parties, setTop3Parties] = useState([]);
  const [top3Candidates, setTop3Candidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  let itemsPerPage = 20;
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(coins?.length / itemsPerPage);

  useEffect(() => {
    setLoading(true);
    const API_URL = "https://api.paradigma.education/api/v2";
    axios
      .get(`${API_URL}/candidates/2022`)
      .then((res) => {
        setCoins(res.data.data);
        setTop3Candidates(res.data.data.top3Candidates);
        setTop3Parties(res.data.data.top3Parties);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <>
      <section className="crypto-politicos-container">
        <div className="search-container">
          <div className="search-input">
            <input
              type="text"
              placeholder="Busca"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="search-icon">
            <img alt="icon search" src={IconSearch} />
          </div>
        </div>

        <div className="ranking-container">
          <div className="ranking-card">
            <h3 className="ranking-title">🏴 Partido mais coiner</h3>
            <ul className="ranking-list">
              {top3Parties?.map((party, index) => (
                  <li className="ranking-item" key={index}>
                    <span>
                      {`${party.name || "-"} (${party.total || 0} CANDIDATOS)`}
                    </span>
                    <span className="bg-green">
                      R${" "}
                      {party?.totalValue
                        ? party.totalValue.toLocaleString("pt-br")
                        : "0"}
                    </span>
                  </li>
                ))}
            </ul>
          </div>

          <div className="ranking-card">
            <h3 className="ranking-title">🐳 Top baleias</h3>
            <ul className="ranking-list">
              {top3Candidates?.map((candidate, index) => (
                <li className="ranking-item" key={index}>
                  <span>{candidate.name || "-"}</span>
                  <span className="bg-green">
                    R${" "}
                    {candidate?.totalValue
                      ? candidate.totalValue.toLocaleString("pt-br")
                      : "-"}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {loading ? (
          <div className="loader-main-container">
            <img className="loader-main" src={LoadingGif} alt="loading..." />
          </div>
        ) : (
          <table className="crypto-politicos-table">
            <Menu data={coins} setData={setCoins} />
            <Item data={coins} searchValue={searchValue} offset={offset} />
          </table>
        )}

        {!coins && <div className="no-data-message">Nenhum dado encontrado.</div>}
        <ReactPaginate
          breakLabel="..."
          previousLabel="<"
          nextLabel=">"
          pageRangeDisplayed={3}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
          containerClassName={
            searchValue
              ? "pagination-wrapper-display-none"
              : "pagination-wrapper"
          }
          pageClassName="pagination-component"
          pageLinkClassName="pagination-text"
          nextLinkClassName="pagination-text"
          previousLinkClassName="pagination-text"
          previousClassName="pagination-component"
          nextClassName="pagination-component"
          breakClassName="pagination-component"
          breakLinkClassName="pagination-text"
        />
      </section>
    </>
  );
};

export default CryptoPoliticos;